<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="approvalApprovers"
    item-value="id"
    :item-text="render"
    clearable
    return-object
  >
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "ApprovalApproversField",
  props: {
    value: Object,
    showKey: {
      type: Boolean,
      default: false,
      required: false
    },
    approvalType: {
      type: Object
    }
  },
  data: () => ({
    approvalApprovers: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    render(item) {
      let response = item.participant.fullName;
      if (this.showKey) {
        response = item.participant.participantKey + " - " + response;
      }
      response = response + " (" + item.name + ")";
      return response;
    }
  },
  mounted() {
    if (!this.approvalType) {
      console.log("No approval type provided to retrieve approvers");
      return;
    }
    ApiService.get("/api/approvalApprovers/byApprovalTypeId/" + this.approvalType.id).then(({ data }) => {
      this.approvalApprovers = data;
    });
  }
};
</script>
