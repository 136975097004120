<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title
        >{{ $i18n.translate("Approval") }} - {{ form.approvalKey }}
        <span v-if="queryPayoutId && selectedPayout"> Create from existing Payout {{ selectedPayout.payoutKey }} </span>
        <span v-else>{{ form.name }}</span>
        <br />
        <v-chip
          :color="form.approved ? 'success' : form.rejected ? 'danger' : 'info'"
          v-if="!loading && selectedApprovalStage"
          >{{ $i18n.translate("Stage") }}: {{ selectedApprovalStage.name }}</v-chip
        >
        &nbsp;
        <v-chip v-if="!loading && selectedAssignedToParticipant"
          >{{ $i18n.translate("Assigned") }}: {{ selectedAssignedToParticipant.fullName }}</v-chip
        >
        <v-chip v-else-if="!loading" color="warning">{{ $i18n.translate("Unassigned") }}</v-chip>
        &nbsp;
        <v-chip :color="selectedPayout && selectedPayout.paid ? 'success' : 'info'" v-if="!loading && selectedPayout"
          >{{ $i18n.translate("Amount") }}: {{ selectedPayout.payoutAmount | toCurrency }}
          <span v-if="selectedPayout && selectedPayout.paid">&nbsp;paid </span></v-chip
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!--
      <v-tooltip
        bottom
        v-if="
          !loading &&
            !isEditing &&
            form.id &&
            !form.closed &&
            ((selectedAssignedToParticipant && selectedAssignedToParticipant.id != selectedParticipant.id) ||
              !selectedAssignedToParticipant)
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" @click="onAssignToMe" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-clipboard-account</v-icon> {{ $i18n.translate("Assign to Me") }}
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Assign to Me") }}</span>
      </v-tooltip>
      -->
      <v-tooltip
        bottom
        v-if="!loading && isEditing && (tab == 0 || tab == 1) && $privilege.hasPrivilege('APPROVAL_UPDATE')"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onSubmit" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>
      <v-tooltip
        bottom
        v-if="!loading && !isNew && (tab == 0 || tab == 1) && $privilege.hasPrivilege('APPROVAL_UPDATE')"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onEdit" v-bind="attrs" v-on="on">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Cancel") }}</span>
        <span v-else>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>

      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab>
            {{ $i18n.translate("Details") }}
          </v-tab>
          <v-tab v-if="form.id">
            {{ $i18n.translate("Uploads") }}
          </v-tab>
          <v-tab v-if="form.id">
            {{ $i18n.translate("Timeline") }}
          </v-tab>
          <v-tab v-if="form.id">
            <v-badge
              icon="mdi-exclamation-thick"
              color="primary"
              offset-x="-2"
              v-if="notesCount > 0"
              :content="notesCount"
              >{{ $i18n.translate("Notes") }}</v-badge
            >
            <span v-else>{{ $i18n.translate("Notes") }}</span>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <div class="text-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-stepper class="ma-2" alt-labels v-if="!loading && approvalStages">
        <v-stepper-header>
          <template v-for="(stage, i) in approvalStages">
            <v-stepper-step
              :editable="isStageEditable(stage)"
              :complete="isStageComplete(stage)"
              :step="i + 1"
              :key="i + '-stage'"
              @click="onChangeStage(stage)"
              :loading="loading"
              :color="selectedApprovalStage && selectedApprovalStage.id == stage.id ? 'primary' : 'info'"
            >
              {{ stage ? stage.name : "" }}
              <small v-if="isStageEditable(stage)">Click to Change</small>
              <small class="red--text" v-else-if="!participantHasStage(selectedParticipant, stage)">{{
                $i18n.translate("Need permission")
              }}</small>
              <small
                class="red--text"
                v-else-if="!selectedAssignedToParticipant || selectedAssignedToParticipant.id != selectedParticipant.id"
                >{{ $i18n.translate("You're not assigned") }}</small
              >
            </v-stepper-step>
            <v-divider :key="i + '-stage-divider'" v-if="i + 1 < approvalStages.length"></v-divider>
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content
            class="grey pa-1 ma-0"
            v-for="(stage, i) in approvalStages"
            :step="i + 1"
            :key="i + '-content'"
          >
            <div class="text-center white--text">Stages are clickable (depending on permissions and status)</div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-dialog v-model="changeStageDialog" max-width="500">
        <v-card :loading="loading">
          <v-card-title> Progress to {{ changeStage ? changeStage.name : "" }}? </v-card-title>
          <v-card-text>
            <p>
              Please confirm that you would like to progress this {{ $i18n.translate("Approval") }} to
              <b>{{ changeStage ? changeStage.name : "" }}</b
              >.
            </p>
            <p></p>
            <p v-if="changeStage && changeStage.autoAssignParticipant">
              {{ $i18n.translate("Approval") }} will be auto-assigned to
              <b>{{ changeStage.autoAssignParticipant.fullName }}</b>
            </p>
            <div v-else>
              <hr style="border: 0px; height: 1px; background-color: gray; margin-bottom: 15px" />
              <p>
                You have the option of assigning someone to this ticket. By leaving this field blank, the assignment of
                this {{ $i18n.translate("Approval") }} will not change.
              </p>
              <ApprovalApproverField
                v-if="selectedApprovalType"
                v-model="changeStageAssignedParticipant"
                label="Assign to"
                :approvalType="selectedApprovalType"
                showKey
              />
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="changeApprovalStage(changeStage)" :loading="loading"
              >Progress to {{ changeStage ? changeStage.name : "" }}</v-btn
            >
            <v-btn @click="onChangeStageCancel()" :loading="loading">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="rejectConfirmDialog" max-width="500">
        <v-card :loading="loading">
          <v-card-title>
            Confirm Rejecting this Approval?
          </v-card-title>
          <v-card-text>
            <p>What is the reason for rejecting this approval?</p>
            <v-text-field label="Reject Reason" v-model="rejectReason"></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="changeApprovalStage(rejectStage)" :loading="loading">Yes</v-btn>
            <v-btn @click="onRejectConfirmCancel()" :loading="loading">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-form :value="valid" @submit.prevent="onSubmit" v-if="!loading" v-model="valid">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <ApprovalSidebar
                    :approval="form"
                    :approvalType="selectedApprovalType"
                    :approvalStage="selectedApprovalStage"
                    :assignedToParticipant="selectedAssignedToParticipant"
                    :approvedUser="selectedApprovedUser"
                    :rejectedUser="selectedRejectedUser"
                    :submittedByParticipant="selectedSubmittedByParticipant"
                    :payout="selectedPayout"
                    :sale="selectedSale"
                    :saleType="selectedSaleType"
                    :saleProducts="selectedSaleProducts"
                    :saleParticipant="selectedSaleParticipant"
                    :saleOrganization="selectedSaleOrganization"
                  />
                </v-col>
                <v-col cols="8">
                  <h2 class="mt-3 mb-6">{{ $i18n.translate("Approval") }} {{ $i18n.translate("Details") }}</h2>
                  <KeyField
                    v-if="form.id"
                    name="approvalKey"
                    label="Key"
                    id="approvalKey"
                    v-model="form.approvalKey"
                    readonly
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'approvalKey')"
                    @input="$error.clearValidationError(errors, 'approvalKey')"
                  ></KeyField>
                  <ApprovalTypeField
                    name="approvalType"
                    id="approvalType"
                    v-if="!form.id"
                    v-model="selectedApprovalType"
                    :label="$i18n.translate('Approval Type')"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'approvalType')"
                    @input="$error.clearValidationError(errors, 'approvalType')"
                    :rules="rules.approvalType"
                    class="required"
                  ></ApprovalTypeField>
                  <v-text-field
                    name="name"
                    :label="$i18n.translate('Approval Name')"
                    v-model="form.name"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'name')"
                    @input="$error.clearValidationError(errors, 'name')"
                  ></v-text-field>
                  <ParticipantField
                    showKey
                    name="assignedToParticipant"
                    id="assignedToParticipant"
                    v-model="selectedAssignedToParticipant"
                    :label="$i18n.translate('Assigned To')"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'assignedToParticipant')"
                    @input="$error.clearValidationError(errors, 'assignedToParticipant')"
                  ></ParticipantField>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="form.id">
            <v-container>
              <v-row>
                <v-col cols="4">
                  <ApprovalSidebar
                    :approval="form"
                    :approvalType="selectedApprovalType"
                    :approvalStage="selectedApprovalStage"
                    :assignedToParticipant="selectedAssignedToParticipant"
                    :approvedUser="selectedApprovedUser"
                    :rejectedUser="selectedRejectedUser"
                    :submittedByParticipant="selectedSubmittedByParticipant"
                    :payout="selectedPayout"
                    :sale="selectedSale"
                    :saleType="selectedSaleType"
                    :saleProducts="selectedSaleProducts"
                    :saleParticipant="selectedSaleParticipant"
                    :saleOrganization="selectedSaleOrganization"
                  />
                </v-col>
                <v-col cols="8">
                  <h2 class="mt-3 mb-6">{{ $i18n.translate("Approval") }} {{ $i18n.translate("Uploads") }}</h2>
                  <UploadTable
                    v-model="approvalUploads"
                    :allowRemoval="true"
                    :disabled="!isEditing"
                    :extraFormats="['.heic']"
                  ></UploadTable>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="form.id">
            <v-container>
              <v-row>
                <v-col cols="4">
                  <ApprovalSidebar
                    :approval="form"
                    :approvalType="selectedApprovalType"
                    :approvalStage="selectedApprovalStage"
                    :assignedToParticipant="selectedAssignedToParticipant"
                    :approvedUser="selectedApprovedUser"
                    :rejectedUser="selectedRejectedUser"
                    :submittedByParticipant="selectedSubmittedByParticipant"
                    :payout="selectedPayout"
                    :sale="selectedSale"
                    :saleType="selectedSaleType"
                    :saleProducts="selectedSaleProducts"
                    :saleParticipant="selectedSaleParticipant"
                    :saleOrganization="selectedSaleOrganization"
                  />
                </v-col>
                <v-col cols="8">
                  <h2 class="mt-3 mb-6">{{ $i18n.translate("Approval") }} {{ $i18n.translate("Timeline") }}</h2>
                  <ActivityTimeline type="approval" :id="form.id" :key="timelineKey"></ActivityTimeline>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item eager v-if="form.id">
            <v-container>
              <v-row>
                <v-col cols="4">
                  <ApprovalSidebar
                    :approval="form"
                    :approvalType="selectedApprovalType"
                    :approvalStage="selectedApprovalStage"
                    :assignedToParticipant="selectedAssignedToParticipant"
                    :approvedUser="selectedApprovedUser"
                    :rejectedUser="selectedRejectedUser"
                    :submittedByParticipant="selectedSubmittedByParticipant"
                    :payout="selectedPayout"
                    :sale="selectedSale"
                    :saleType="selectedSaleType"
                    :saleProducts="selectedSaleProducts"
                    :saleParticipant="selectedSaleParticipant"
                    :saleOrganization="selectedSaleOrganization"
                  />
                </v-col>
                <v-col cols="8">
                  <h2 class="mt-3 mb-6">{{ $i18n.translate("Approval") }} {{ $i18n.translate("Notes") }}</h2>
                  <ApprovalNotesTable @updateCount="onNoteUpdate" v-if="form.id" :approval="form" />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiError from "../../display/ApiError.vue";
import ApprovalApproverField from "../../fields/ApprovalApproverField.vue";
import ApprovalTypeField from "../../fields/ApprovalTypeField.vue";
import KeyField from "../../fields/KeyField.vue";
import ParticipantField from "../../fields/ParticipantField.vue";
import ActivityTimeline from "../../tables/ActivityTimeline.vue";
import ApprovalNotesTable from "../../tables/ApprovalNotesTable.vue";
import UploadTable from "../../tables/UploadTable.vue";
import ApprovalSidebar from "./ApprovalSidebar.vue";

import { mapGetters } from "vuex";

export default {
  name: "Approval",
  metaInfo: {
    title: "Approval"
  },
  components: {
    ApiError,
    KeyField,
    ApprovalTypeField,
    ParticipantField,
    UploadTable,
    ActivityTimeline,
    ApprovalNotesTable,
    ApprovalSidebar,
    ApprovalApproverField
  },
  data: function() {
    return {
      approvalId: 0,
      timelineKey: 0,
      tab: null,
      valid: false,
      isNew: false,
      isEditing: false,
      loading: false,
      form: {},
      approvalUploads: {
        existing: [],
        deleted: []
      },
      errors: {},
      rules: {
        approvalType: [v => !!v || "Approval Type is required"]
      },
      notesCount: 0,

      changeStageDialog: false,
      changeStage: null,
      changeStageAssignedParticipant: null,

      rejectConfirmDialog: false,
      rejectReason: "",
      rejectStage: null,

      queryPayoutId: null,

      approvalApprover: null,
      approvalApprovers: [],
      approvalStages: [],

      selectedApprovalType: null,
      selectedApprovalStage: null,
      selectedPayout: null,
      selectedSale: null,
      selectedSaleType: null,
      selectedSaleProducts: null,
      selectedSaleParticipant: null,
      selectedSaleOrganization: null,
      selectedAssignedToParticipant: null,
      selectedSubmittedByParticipant: null,
      selectedApprovedUser: null,
      selectedRejectedUser: null
    };
  },
  methods: {
    onAssignToMe() {
      this.selectedAssignedToParticipant = this.selectedParticipant;
      this.onSubmit();
    },
    onSubmit() {
      this.loading = true;
      this.errors = {};

      let serviceCall = null;

      let postForm = Object.assign({}, this.form);

      if (this.isNew) {
        if (this.selectedPayout) {
          postForm.payout = { id: this.selectedPayout.id };
        }
        if (this.selectedApprovalType) {
          postForm.approvalType = { id: this.selectedApprovalType.id };
        }
        if (this.selectedAssignedToParticipant) {
          postForm.assignedToParticipant = { id: this.selectedAssignedToParticipant.id };
        }
        serviceCall = this.$api.post("/api/approvals/create", postForm);
      } else {
        // set upload
        if (this.approvalUploads.existing) {
          postForm.uploads = this.approvalUploads.existing.map(upload => this.$api.getSelfUrl("uploads", upload));
        }
        if (this.selectedAssignedToParticipant) {
          postForm.assignedToParticipant = this.$api.getSelfUrl("participants", this.selectedAssignedToParticipant);
        } else {
          postForm.assignedToParticipant = null;
        }

        serviceCall = this.$api.patch("/api/approvals/" + this.$route.params.id, postForm);
      }
      return serviceCall
        .then(({ data }) => {
          this.form = data;
          this.isEditing = false;
          if (this.isNew) {
            this.isNew = false;
            this.$route.params.id = data.id;
            this.approvalId = data.id;
          }
          return this.saveUploads().then(() => {
            return this.fetchData();
          });
        })
        .catch(error => {
          this.isEditing = true;
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onNoteUpdate(count) {
      if (this.notesCount != count) {
        this.notesCount = count;
        this.timelineKey++;
      }
    },
    onChangeStageCancel() {
      this.changeStageAssignedParticipant = null;
      this.changeStageDialog = false;
      this.changeStage = null;
    },
    showChangeStageDialog(stage) {
      this.changeStageAssignedParticipant = null;
      this.changeStageDialog = true;
      this.changeStage = stage;
    },
    onRejectConfirmCancel() {
      this.rejectReason = "";
      this.rejectConfirmDialog = false;
      this.rejectStage = null;
    },
    onRejectConfirm(stage) {
      this.rejectReason = "";
      this.rejectConfirmDialog = true;
      this.rejectStage = stage;
    },
    onChangeStageConfirm(stage) {
      this.changeStage(stage);
    },
    onChangeStage(stage) {
      if (!this.isStageEditable(stage)) {
        return;
      }
      if (stage.rejectedStage) {
        this.onRejectConfirm(stage);
      } else {
        this.showChangeStageDialog(stage);
      }
    },
    changeApprovalStage(stage) {
      if (this.isStageEditable(stage)) {
        this.loading = true;
        this.errors = {};

        let postForm = {};
        if (this.rejectReason && this.rejectReason.trim().length > 0) {
          postForm.rejectedReason = this.rejectReason;
        }
        if (this.changeStageAssignedParticipant) {
          postForm.assignParticipant = { id: this.changeStageAssignedParticipant.participant.id };
        }
        return this.$api
          .post("/api/approvals/" + this.form.id + "/stage/" + stage.id, postForm)
          .then(() => {
            return this.fetchData();
          })
          .catch(error => {
            this.errors = this.$api.getErrorsFromResponse(error);
          })
          .finally(() => {
            this.onRejectConfirmCancel();
            this.onChangeStageCancel();
            this.loading = false;
          });
      }
    },
    isStageEditable(stage) {
      let particiantIsAssigned =
        this.selectedAssignedToParticipant && this.selectedAssignedToParticipant.id == this.selectedParticipant.id;
      let stageIsNotCurrentStage = this.selectedApprovalStage && stage.id != this.selectedApprovalStage.id;
      let participantHasCurrentApprovalStage = this.participantHasStage(
        this.selectedParticipant,
        this.selectedApprovalStage
      );
      let participantHasThisStage = this.participantHasStage(this.selectedParticipant, stage);
      let canProgressToStage =
        participantHasThisStage ||
        stage.rankOrder - this.selectedApprovalStage.rankOrder == 1 ||
        stage.rankOrder - this.selectedApprovalStage.rankOrder == -1;
      let approvalIsNotClosed = !this.form.closed && !this.form.approved && !this.form.rejected;
      let payoutIsPaid = this.selectedPayout && this.selectedPayout.paid;

      return (
        particiantIsAssigned &&
        stageIsNotCurrentStage &&
        participantHasCurrentApprovalStage &&
        canProgressToStage &&
        (approvalIsNotClosed || (!approvalIsNotClosed && !payoutIsPaid))
      );
    },
    isStageComplete(stage) {
      let currentStageIndex = 0;
      let providedStageIndex = 0;
      for (let i = 0; i < this.approvalStages.length; i++) {
        let approvalStage = this.approvalStages[i];
        if (approvalStage.id == this.selectedApprovalStage.id) {
          currentStageIndex = i;
        }
        if (approvalStage.id == stage.id) {
          providedStageIndex = i;
        }
      }
      return providedStageIndex <= currentStageIndex;
    },
    getApprover() {
      if (!this.approvalApprover) {
        for (let i = 0; i < this.approvalApprovers.length; i++) {
          let approvalApprover = this.approvalApprovers[i];
          if (approvalApprover.participant.id == this.selectedParticipant.id) {
            this.approvalApprover = approvalApprover;
          }
        }
      }
      return this.approvalApprover;
    },
    participantHasStage(participant, stage) {
      for (let i = 0; i < this.approvalApprovers.length; i++) {
        let approvalApprover = this.approvalApprovers[i];
        if (approvalApprover.participant.id == participant.id) {
          for (let j = 0; j < approvalApprover.approvalStages.length; j++) {
            let approvalStage = approvalApprover.approvalStages[j];
            if (approvalStage.id == stage.id) {
              return true;
            }
          }
        }
      }
      return false;
    },
    saveUploads() {
      let promiseArray = [];
      if (this.approvalUploads.existing) {
        this.approvalUploads.existing.forEach(document => {
          if (document.dirty) {
            promiseArray.push(this.$api.patch("/api/uploads/" + document.id, { tag: document.tag }));
          }
        });
      }
      if (this.approvalUploads.deleted) {
        this.approvalUploads.deleted.forEach(document => {
          promiseArray.push(this.$api.delete("/api/uploads/remove/" + document.id));
        });
      }

      return Promise.all(promiseArray);
    },
    onEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.fetchData();
      }
    },
    fetchData() {
      if (!this.isNew) {
        this.loading = true;
        return this.$api
          .get("/api/approvals/" + this.$route.params.id)
          .then(({ data }) => {
            this.form = data;
            this.breadcrumb();
            return Promise.all([
              this.$api.getRelatedObject("approvalStage", data).then(({ data }) => {
                this.selectedApprovalStage = data;
              }),
              this.$api.getRelatedObject("approvalType", data).then(({ data }) => {
                this.selectedApprovalType = data;
                return Promise.all([
                  this.$api
                    .get("/api/approvalApprovers/byApprovalTypeId/" + this.selectedApprovalType.id)
                    .then(({ data }) => {
                      this.approvalApprovers = data;
                      this.getApprover();
                    }),
                  this.$api
                    .get("/api/approvalStages/byApprovalTypeId/" + this.selectedApprovalType.id)
                    .then(({ data }) => {
                      this.approvalStages = data;
                    })
                ]);
              }),
              this.$api
                .getRelatedObject("assignedToParticipant", data)
                .then(({ data }) => {
                  this.selectedAssignedToParticipant = data;
                })
                .catch(() => {
                  console.log("Assigned To Participant not found");
                }),
              this.$api.getRelatedObject("submittedByParticipant", data).then(({ data }) => {
                this.selectedSubmittedByParticipant = data;
              }),
              this.$api.getRelatedObject("uploads", data).then(({ data }) => {
                this.approvalUploads.existing = data._embedded.uploads;
              }),
              this.fetchApprovedByUser(data),
              this.fetchRejectedByUser(data),
              this.fetchPayoutByPayoutId(data.payoutId)
            ]);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (this.queryPayoutId) {
          this.loading = true;
          return this.fetchPayoutByPayoutId(this.queryPayoutId).finally(() => {
            this.loading = false;
          });
        } else {
          return Promise.resolve();
        }
      }
    },
    fetchPayoutByPayoutId(payoutId) {
      return this.$api.get("/api/payouts/" + payoutId).then(({ data }) => {
        this.selectedPayout = data;
        return Promise.all([
          this.$api.getRelatedObject("sale", data).then(({ data }) => {
            this.selectedSale = data;
            return Promise.all([
              this.$api.getRelatedObject("saleType", data).then(({ data }) => {
                this.selectedSaleType = data;
              }),
              this.$api.get("/api/saleProducts/bySaleId/" + this.selectedSale.id + "?size=50").then(({ data }) => {
                this.selectedSaleProducts = data.content;
                if (this.isNew && this.selectedSaleProducts.length == 1 && this.selectedSaleProducts[0].serialNumber) {
                  this.form.name = "Approval for " + this.selectedSaleProducts[0].serialNumber;
                }
              }),
              this.$api.getRelatedObject("participant", data).then(({ data }) => {
                this.selectedSaleParticipant = data;
              }),
              this.$api.getRelatedObject("organization", data).then(({ data }) => {
                this.selectedSaleOrganization = data;
              })
            ]);
          })
        ]);
      });
    },
    fetchApprovedByUser(approval) {
      if (approval.approved) {
        return this.$api.getRelatedObject("approvedUser", approval).then(({ data }) => {
          this.selectedApprovedUser = data;
        });
      } else {
        return Promise.resolve();
      }
    },
    fetchRejectedByUser(approval) {
      if (approval.rejected) {
        return this.$api.getRelatedObject("rejectedUser", approval).then(({ data }) => {
          this.selectedRejectedUser = data;
        });
      } else {
        return Promise.resolve();
      }
    },
    breadcrumb() {
      if (this.isNew) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Approvals",
            to: { name: "approvals" },
            exact: true
          },
          { text: "New Approval" }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Approvals",
            to: { name: "approvals" },
            exact: true
          },
          { text: this.form.name }
        ]);
      }
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedProgram"]),
    title() {
      return "Approval - " + this.form.name;
    }
  },

  mounted() {
    this.approvalId = parseInt(this.$route.params.id);
    this.queryPayoutId = parseInt(this.$route.query.payoutId);
    if (this.$route.params.id == 0) {
      this.isNew = true;
      this.isEditing = true;
      this.breadcrumb();
    }

    this.fetchData();
  }
};
</script>
