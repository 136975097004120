<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="total"
      :options.sync="options"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Approval Notes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-form @submit.stop.prevent="onSubmit">
            <v-text-field
              class="mr-4"
              v-model="search"
              :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Notes')"
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
              @click:clear="onClear"
            ></v-text-field>
          </v-form>
          <v-dialog v-model="dialog" v-if="!disabled" width="800">
            <template v-slot:activator="{ on: onDialog, attrs: attrsDialog }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                  <v-btn fab small v-bind="{ ...attrsDialog, ...attrsTooltip }" v-on="{ ...onDialog, ...onTooltip }">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>New Note</span>
              </v-tooltip>
            </template>
            <v-form ref="approvalNoteForm" @submit.prevent="onSave" v-model="valid">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <ApiError :errors="errors"></ApiError>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          name="note"
                          label="Note"
                          id="note"
                          v-model="editedItem.note"
                          :rules="rules.note"
                          :error-messages="$error.getValidationError(errors, 'note')"
                          @input="$error.clearValidationError(errors, 'note')"
                          class="required"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text :loading="loading" @click="onClose">{{
                    $i18n.translate("Cancel")
                  }}</v-btn>
                  <v-btn color="blue darken-1" text :loading="loading" @click="onSave">{{
                    $i18n.translate("Save")
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.createdDate="{ item }">
        {{ item.createdDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
        ({{ item.createdDate | formatDateFromNow }})
      </template>
      <template v-slot:item.note="{ item }">
        <div style="word-wrap:break-all;">
          {{ item.note }}
        </div>
      </template>
      <template v-slot:item.user="{ item }">
        {{ item.user.fullName }}
      </template>
      <template v-slot:item.actions="{ item }" v-if="!disabled">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="onDelete(item)">mdi-delete</v-icon>
          </template>
          <span>Delete Note</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ApiError from "../display/ApiError.vue";
import { mapGetters } from "vuex";

export default {
  components: { ApiError },
  name: "ApprovalNotesTable",
  props: {
    approval: Object,
    disabled: Boolean
  },
  data: () => ({
    valid: false,
    dialog: false,

    loading: false,
    search: null,
    total: 0,
    options: {
      sortBy: ["updatedDate"],
      sortDesc: [true],
      itemsPerPage: 10,
      page: 1
    },

    form: {},
    errors: {},

    items: [],
    headers: [
      {
        value: "createdDate",
        text: "Date",
        sortable: true
      },
      {
        value: "note",
        text: "Note",
        width: "50%",
        sortable: true
      },
      {
        value: "user",
        text: "User",
        sortable: true
      },
      { text: "Actions", value: "actions", align: "center", sortable: false }
    ],
    rules: {
      note: [
        v => !!v || "Message is required",
        v => (!!v && v.length <= 3000) || "Message length should be less or equal than 3000 characters"
      ]
    },
    editedIndex: -1,
    editedItem: {},
    defaultItem: {}
  }),

  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Note" : "Edit Note";
    }
  },

  watch: {
    dialog(val) {
      if (val) {
        if (this.$refs.approvalNoteForm) {
          this.$refs.approvalNoteForm.reset();
        }
        this.editedItem = {};
      }
    },
    options: {
      handler() {
        this.fetchData();
      },
      deep: true,
      immediate: true
    },
    approval: {
      handler() {
        this.fetchData();
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    onClear() {
      this.search = "";
      this.fetchData();
    },

    onSubmit() {
      this.options.page = 0;
      this.fetchData();
    },

    onDelete(approvalNote) {
      this.loading = true;
      this.$api
        .delete("/api/approvalNotes/" + approvalNote.id)
        .then(() => this.fetchData())
        .finally(() => {
          this.loading = false;
          this.$emit("approvalNoteDeleted", approvalNote);
        });
    },

    fetchData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {};
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }
      if (this.approval) {
        filters.approval = { id: this.approval.id };
      }

      return this.$api
        .post(
          "/api/approvalNotes/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
          this.$emit("updateCount", this.total);
          return Promise.resolve(data);
        })
        .catch(e => {
          this.loading = false;
          return Promise.reject(e);
        });
    },

    onEditItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.valid = false;
    },

    onClose() {
      this.dialog = false;
      this.$emit("approvalNote", this.approvalNote);
    },

    onSave() {
      this.errors = {};
      if (this.editedItem.id) {
        this.$api
          .patch("/api/approvalNotes/" + this.editedItem.id, this.editedItem)
          .then(() => {
            return this.fetchData().finally(() => {
              this.onClose();
            });
          })
          .catch(error => {
            this.errors = this.$api.getErrorsFromResponse(error);
          });
      } else {
        this.editedItem.approval = { id: this.approval.id };
        this.$api
          .post("/api/approvalNotes", this.editedItem)
          .then(() => {
            return this.fetchData().finally(() => {
              this.onClose();
            });
          })
          .catch(error => {
            this.errors = this.$api.getErrorsFromResponse(error);
          });
      }
    }
  }
};
</script>
